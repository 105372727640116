const Config = {
  datatableFeatures: {
    buttons: {
      createButton: false,
      refresh: true,
    },
    datepicker: true,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: true,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'company_name', text: 'Company Name' },
    { value: 'pic_name', text: 'PIC Name' },
    { value: 'pic_email', text: 'PIC Email' },
  ],
  formData() {
    return {
      title: {
        edit: 'Edit Bank',
        view: 'View Bank',
        remove: 'Remove Bank',
        create: 'Create New Bank',
      },
      form: [{
        formContext: 'Account',
        formName: 'account',
        elem: [
          {
            key: 'name',
            fieldName: 'Name',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'code',
            fieldName: 'Code',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'bank_branch',
            fieldName: 'Bank Branch',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'account_number',
            fieldName: 'Account Number',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'bank_account_statement',
            fieldName: 'Bank Account Statement',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'description',
            fieldName: 'Description',
            type: 'textarea',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            customValidation: true,
            key: 'record_status',
            fieldName: 'Active?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
        ],
      }],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: true, param: 'id', btnLabel: 'View', action: 'openModal', btnIcon: 'SearchIcon', btnOnly: true, variant: 'secondary', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'secondary', size: 'sm', iconSize: '12',
        },
        {
          enable: true, param: 'id', btnLabel: 'Approve', action: 'approve', btnIcon: '', btnOnly: false, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: true, param: 'id', btnLabel: 'Reject', action: 'reject', btnIcon: '', btnOnly: false, variant: 'danger', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },
        {
          key: 'id', label: 'ID', sortable: false, visible: false,
        },
        {
          key: 'comp_name', label: 'Company Name', sortable: false, visible: true,
        },
        {
          key: 'pic_name', label: 'PIC Name', sortable: false, visible: true,
        },
        {
          key: 'pic_email', label: 'PIC Email', sortable: false, visible: true,
        },
        {
          key: 'user_id', label: 'User Id', sortable: true, visible: false,
        },
        {
          key: 'product_category_text', label: 'Category', sortable: true, visible: true,
        },
        {
          key: 'company_type', label: 'Company Type', sortable: true, visible: false,
        },
        {
          key: 'submission_at', label: 'Submission at', sortable: true, visible: true,
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: false,
        },
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: false,
        },
        {
          key: 'status', label: 'Record Status', sortable: true, visible: true,
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: true,
        },
      ],
    }
  },
}

export default Config
