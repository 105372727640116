<template>
  <div>
    <leanpay-datatable-component-onboard
      :tab-object="[{tabName: 'Pending', disabled: false, active: false, tabValue: 1},{tabName: 'Pending Signature', disabled: false, active: false, tabValue: 3},{tabName: 'Pending Approval', disabled: false, active: true, tabValue: 2},{tabName: 'Approved', disabled: false, active: false, tabValue: 4},{tabName: 'Rejected', disabled: false, active: false, tabValue: 5}]"
      title="Onboarding"
      store-module="onboarding"
      store-data-endpoint="getPortalOnboardingList"
      store-getter-endpoint="getPortalOnboardingList"
      pagination-state-key="auditRequestsList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="company_name"
      default-sort-field="submission_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
    />
  </div>
</template>

<script>
import Config from '@/views/administration/onboarding/formConfig'
import humaniseMixin from '@/common/humanise.mixin'
import leanpayDatatableComponentOnboard from '@/leanpay-components/LeanpayDatatableComponentOnboard.vue'

export default {
  components: { leanpayDatatableComponentOnboard },
  mixins: [humaniseMixin],
  data() {
    return {
      cardTitle: 'Onboarding',
      dataGridEndpoint: [],
      module: 'onboarding',

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortBy: 'created_at',
      sortDesc: true,

      // 1: approved
      // 2: pending
      // 3: rejected
      formStatus: 2,

      record_status: 1, // active only
      searchField: 'name',

      searchFieldOpts: [
        { value: 'full_name', text: 'Full Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
        { value: 'invoice_status', text: 'Invoice Status' },
      ],
    }
  },
  computed: {
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
    tableConfig() {
      if (Config) {
        return Config.tableData()
      }
      return {}
    },
    tableSettings() {
      const list = this.$store.getters[`${this.module}/getPaginationInfo`]
      // const { list } = dummyRes.data
      const perPage = this.selectedPerPage
      let listLength
      if (list !== null) {
        listLength = this.GridData.recordsTotal
      } else {
        listLength = 100
      }
      return {
        rows: listLength,
        perPage,
        refresh: true,
        filter: false,
        pagination: true,
        search: true,
      }
    },
    TableHeader() {
      const x = this.tableConfig.tableHeader
      return x.filter(y => y.visible)
    },
    TableActions() {
      const x = this.tableConfig.tableAction
      return x.filter(y => y.enable)
    },
    sortObj() {
      const obj = {
        parameter_name: this.sortBy,
        sort_type: this.sortDesc ? 'desc' : 'asc',
      }
      return obj
    },
    GridData() {
      const list = this.$store.getters[`${this.module}/getPortalOnboardingList`]
      if (list !== null) {
        return list
      }
      return []
    },
    GridSettings() {
      const list = this.$store.getters[`${this.module}/getPaginationInfo`]

      console.log('---->', list)
      if (list !== null) {
        return {
          draw: list.draw,
          recordsTotal: list.record_total,
          next_page: list.next_page,
          next_page_length: list.next_page_length,
          next_page_start: list.next_page_start,
          previous_page_length: list.previous_page_length,
          previous_page_start: list.previous_page_start,
          previouse_page: list.previouse_page,
          recordsFiltered: list.record_filtered,
        }
      }
      return []
    },

  },
  async mounted() {
    this.isBusy = false
    const breadcrumbUpdatePayload = [
      { title: 'Onboarding' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
    await this.$store.dispatch('systemConstants/getSystemConstants')
    await this.$store.dispatch('systemConstants/getOnboardSystemConstants')

    this.initDataGrid()
  },
  methods: {
    transposeStatus(id) {
      const formStatuses = this.$store.getters['systemConstants/getOnboardSystemConstantsByKey']('formStatus')

      if (typeof formStatuses === 'object' && formStatuses !== null) {
        return Object.keys(formStatuses).find(key => formStatuses[key] === id)
      }
      return typeof formStatuses
    },
    processProductCat(string) {
      return string.length
    },
    editRow(itemId) {
      console.log('edit', itemId)
      this.$router.push({
        name: 'administration-banks-form',
        params: { id: itemId, mode: 'edit' },
      })
    },
    deleteRow(itemId) {
      console.log('delete', itemId)
    },
    download(itemId) {
      // downloadOnboardingFile
      console.log('download', itemId)
      this.$store.dispatch(`${this.module}/downloadOnboardingFile`, itemId)
    },
    approveRow(formId, userType) {
      console.log('hardcoded userType ', userType)
      const payload = {
        // mastermerchant : 3
        // whitelabel : 4
        user_type: 3,
        form_id: formId,
      }
      console.log(payload)

      const swalSettings = {
        ...this.swalDefault,
        title: 'Confirm Approve?',
        confirmButtonText: 'Confirm',
        customClass: {
          container: 'confirmation-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }

      this.$swal(swalSettings).then(result => {
        console.log(result)
        if (result.isConfirmed) {
          // this.$store.dispatch(`${this.module}/getPortalOnboardingList`, payload)
          this.$store.dispatch(`${this.module}/approvePortalOnboardingUser`, payload).then(() => {
            this.initDataGrid()
          })
        }
      })
    },
    rejectRow(userId, formId) {
      const payload = {
        form_id: formId,
      }

      const swalSettings = {
        ...this.swalDefault,
        title: 'Confirm Reject?',
        confirmButtonText: 'Confirm',
        customClass: {
          // container: 'confirmation-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-danger mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }

      this.$swal(swalSettings).then(result => {
        console.log(result)
        if (result.isConfirmed) {
          // this.$store.dispatch(`${this.module}/getPortalOnboardingList`, payload)
          this.$store.dispatch(`${this.module}/rejectPortalOnboardingUser`, payload).then(() => {
            this.initDataGrid()
          })
        }
      })
    },
    viewRow(itemId) {
      console.log('view', itemId)
      this.$router.push({
        name: 'administration-banks-form',
        params: { id: itemId, mode: 'view' },
      })
    },
    create() {
      this.$router.push({
        name: 'administration-banks-new',
      })
    },
    pagination(e) {
      if (this.tableSettings.search) {
        const payload = {
          start: (e - 1) * this.tableSettings.perPage,
          length: this.tableSettings.perPage,
          sort: this.sortObj,
          search: this.searchTerm,
        }
        this.isBusy = true
        this.$store.dispatch(this.dataGridEndpoint, payload).then(() => {
          this.isBusy = false
        })
      }
    },
    sortingChanged(ctx) {
      this.sortDesc = !this.sortDesc
      this.sortBy = ctx.sortBy
      const payload = {
        start: 0,
        length: this.tableSettings.perPage,
        sort: this.sortObj,
        search: this.searchTerm,
      }
      this.isBusy = true
      this.$store.dispatch(this.dataGridEndpoint, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    searchGrid(vuexEndpoint) {
      const payload = {
        start: 0,
        length: this.tableSettings.perPage,
        sort: this.sortObj,
        search: this.searchTerm,
      }
      this.isBusy = true
      this.$store.dispatch(vuexEndpoint, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    initDataGrid(type = 2) {
      this.isBusy = true

      // const payload = {
      //
      //   form_status: type,
      //   limit: this.tableSettings.perPage,
      //   skip: 0,
      //   search: {
      //     search_enable: false,
      //     search_key: '',
      //     search_column: '',
      //     search_replace_word_enable: true,
      //     search_word_replace: ' ',
      //     search_word_replace_to: '_',
      //   },
      //   sort: this.sortObj,
      // }
      const payload = {
        form_status: type,
        page: 1,
        per_page: this.tableSettings.perPage,
        start_date: '01-05-2017',
        end_date: '01-12-2099',
        search_column: '',
        search_key: '',
        sort_column: 'created_at',
        sort_type: this.sortObj.sort_type,
      }
      this.$store.dispatch(`${this.module}/getPortalOnboardingList`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false

        this.searchTerm = ''
      })
    },
  },
}
</script>
