<template>
  <div>
    <b-card no-body>
      <template #header>
        <h4 class="mr-auto mb-0">
          {{ title }}
        </h4>
        <b-button
          v-if="features.buttons.createButton"
          variant="primary"
          size="sm"
          @click="create"
        >
          Create New
        </b-button>
      </template>
      <div
        v-if="tabObject.length > 0"
        class="border-top border-bottom px-2 py-1"
      >
        <b-row>
          <b-col>
            <template v-for="(tab, index) in tabObject">
              <b-button
                :key="index"
                :class="[{'btn-secondary': tab.active}]"
                :disabled="tab.disabled"
                variant="primary"
                class="mr-1"
                @click="initDataGrid(tab.tabValue)"
              >
                {{ tab.tabName }}
              </b-button>
            </template>
          </b-col>
        </b-row>
      </div>

      <b-row v-if="!!tableSettings">
        <b-col cols="4">
          <b-form-group
            class="my-2"
            label-size="sm"
          >
            <template v-if="features.buttons.refresh">
              <b-button
                size="sm"
                class="mx-2"
                @click="initDataGrid(currentTab)"
              >
                <i class="fa fa-sync" /> Refresh
              </b-button>
            </template>
            <template v-if="features.perPageDropdown">
              <b-form-select
                v-model="selectedPerPage"
                :class="[{'ml-2': !features.buttons.refresh}]"
                class="w-25"
                :options="perPageOptions"
                size="sm"
                @change="initDataGrid(currentTab)"
              />
            </template>
          </b-form-group>
        </b-col>

        <b-col cols="8">
          <div class="mt-2 mr-1">

            <template v-if="features.filter">
              <b-col>
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="text"
                    placeholder="Type to filter"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </template>
            <template v-if="features.search">
              <b-col>
                <b-row>
                  <b-col v-if="features.datepicker">
                    <b-form-group>
                      <template v-if="features.datepicker">
                        <b-col>
                          <b-input-group size="sm">
                            <flat-pickr
                              v-model="rangeDate"
                              placeholder="Select Date"
                              class="form-control form-control-sm"
                              :config="{ mode: 'range',
                                         maxDate: new Date().fp_incr(1),
                                         dateFormat: 'd-m-Y',}"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-secondary"
                                type="button"
                                title="Clear"
                                data-clear
                                @click="clearDateSearch"
                              >
                                Clear
                              </button>

                            </div>
                          </b-input-group>
                        </b-col>
                      </template>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="features.searchField"
                    cols="3"
                  >
                    <b-form-select
                      v-if="features.searchField"
                      v-model="searchField"
                      :options="searchOptionsDropdown"
                      size="sm"
                    />

                  </b-col>
                  <b-col>
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="searchTerm"
                        type="text"
                        placeholder="Type to Search"
                      />

                      <b-input-group-append>
                        <b-button
                          v-if="searchTerm"
                          @click="clearDateSearch"
                        >Clear</b-button>
                        <b-button
                          :disabled="!rangeDate && !searchTerm"
                          @click="searchGrid()"
                        >Search</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>

              </b-col>

            </template>
          </div>
        </b-col>
      </b-row>

      <b-table
        :filter="filter"
        :no-local-sorting="true"
        striped
        bordered
        responsive
        show-empty
        hover
        :items="GridData"
        :busy="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :fields="TableHeader"
        @sort-changed="sortingChanged"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
            <br>
            <strong>Loading...</strong>
          </div>
        </template>
        <template
          v-for="x in TableHeader"
          v-slot:[getTableKey(x)]="row"
        >
          <template v-if="x.type === 'monetary'">
            <vue-numeric
              :key="x.id"
              read-only
              :precision="2"
              :currency="myCurrency"
              separator=","
              :value="row.item[x.key]"
            />
          </template>
          <template v-if="x.type === 'string'">
            {{ humanise(row.item[x.key]) }}
          </template>
          <template v-if="x.type === 'date'">
            {{ transformDate(row.item[x.key], false, 'FF' ) }}
          </template>
          <template v-if="x.type === 'flag'">
            {{ displaySystemConstantsLabel(x.key, row.item[x.key]) }}
          </template>
          <template v-if="x.type === 'number'">
            {{ row.item[x.key] }}
          </template>
          <template v-if="x.type === 'decimal'">
            {{ row.item[x.key] }}
          </template>
          <template v-if="x.type === undefined">
            {{ row.item[x.key] }}
          </template>
        </template>

        <template #cell(status)="row">
          <!--        <span :class="[{'text-success': row.item.status === 1}, {'text-warning': row.item.status === 2}]">-->
          <!--          {{ displaySystemConstantsLabel('record_status', row.item.status) }}</span>-->
          {{ row.item.status }}
        </template>
        <template #cell(created_at)="row">
          <!--        {{row.item.created_at}}-->
          {{ transformDate(row.item.created_at, false, 'FF' ) }}
        </template>
        <template #cell(submission_at)="row">
          <!--        {{row.item.created_at}}-->
          {{ transformDate(row.item.submission_at, false, 'FF' ) }}
        </template>
        <template #cell(updated_at)="row">
          <!--        {{row.item.created_at}}-->
          {{ transformDate(row.item.updated_at, false, 'FF' ) }}
        </template>
        <template #cell(comp_name)="row">
          <!--        {{row.item.created_at}}-->
          {{ row.item.info.companyName }}
        </template>
        <template #cell(pic_name)="row">
          <!--        {{row.item.created_at}}-->
          {{ row.item.info.contactName }}
        </template>
        <template #cell(pic_email)="row">
          <!--        {{row.item.created_at}}-->
          {{ row.item.info.contactEmail }}
        </template>
        <template #cell(product_category_text)="row">
          <!--        {{row.item.created_at}}-->
          <template v-for="(index) in row.item.product_category_text.length">
            <b-badge
              :key="`'CAT-${index-0 }`"
              variant="primary"
              style="margin: 0 5px 5px 0"
            >
              {{ row.item.product_category_text[index-1] }}
            </b-badge>
          </template>
          <!--        {{ processProductCat(row.item.product_category_text) }}-->
        </template>

        <template #cell(actions)="row">
          <template v-if="TableActions.length > 0">
            <b-button-group size="sm">
              <template v-for="(btn,index) in TableActions">
                <template v-if="btn.action === 'openModal'">
                  <b-button
                    :key="`button_actions-${index}`"
                    :variant="btn.variant"
                    @click="openModalRow(row.item, row.item[btn.param])"
                  >
                    <feather-icon
                      v-if="btn.btnIcon"
                      :size="btn.iconSize"
                      :icon="btn.btnIcon"
                    />
                    <template v-if="!btn.btnOnly">
                      {{ btn.btnLabel }}
                    </template>
                  </b-button>
                </template>
                <template v-if="btn.action === 'view'">
                  <b-button
                    :key="`button_actions-${index}`"
                    :variant="btn.variant"
                    @click="viewRow(row.item, row.item[btn.param])"
                  >
                    <feather-icon
                      v-if="btn.btnIcon"
                      :size="btn.iconSize"
                      :icon="btn.btnIcon"
                    />
                    <template v-if="!btn.btnOnly">
                      {{ btn.btnLabel }}
                    </template>
                  </b-button>
                </template>
                <template v-if="btn.action === 'edit'">
                  <b-button
                    :key="`button_actions-${index}`"
                    :variant="btn.variant"
                    @click="editRow(row.item[btn.param])"
                  >
                    <feather-icon
                      v-if="btn.btnIcon"
                      :size="btn.iconSize"
                      :icon="btn.btnIcon"
                    />
                    <template v-if="!btn.btnOnly">
                      {{ btn.btnLabel }}
                    </template>
                  </b-button>
                </template>
                <template v-if="btn.action === 'approve' && row.item.status === 2">
                  <b-button
                    :key="`button_actions-${index}`"
                    :variant="btn.variant"
                    @click="approveRow(row.item.id, row.item.user_type)"
                  >
                    <feather-icon
                      v-if="btn.btnIcon"
                      :size="btn.iconSize"
                      :icon="btn.btnIcon"
                    />
                    <template v-if="!btn.btnOnly">
                      {{ btn.btnLabel }}
                    </template>
                  </b-button>
                </template>
                <template v-if="btn.action === 'reject' && row.item.status === 2">
                  <b-button
                    :key="`button_actions-${index}`"
                    :variant="btn.variant"
                    @click="rejectRow(row.item.user_id, row.item.id)"
                  >
                    <feather-icon
                      v-if="btn.btnIcon"
                      :size="btn.iconSize"
                      :icon="btn.btnIcon"
                    />
                    <template v-if="!btn.btnOnly">
                      {{ btn.btnLabel }}
                    </template>
                  </b-button>
                </template>
                <template v-if="btn.action === 'remove'">
                  <b-button
                    :key="`button_actions-${index}`"
                    :variant="btn.variant"
                    @click="deleteRow(row.item[btn.param])"
                  >
                    <feather-icon
                      v-if="btn.btnIcon"
                      :size="btn.iconSize"
                      :icon="btn.btnIcon"
                    />
                    <template v-if="!btn.btnOnly">
                      {{ btn.btnLabel }}
                    </template>
                  </b-button>
                </template>

              </template>
            </b-button-group>
          </template>

        </template>

        <!--Show Details UI-->
        <template
          v-if="features.showDTDetails"
          #cell(show_details)="row"
        >
          <template v-if="row.detailsShowing">
            <b-button
              variant="link"
            >
              <feather-icon
                size="16"
                icon="MinusCircleIcon"
                class="mr-50"
                @click="row.toggleDetails"
              />
            </b-button>
          </template>
          <template v-if="!row.detailsShowing">
            <b-button
              variant="link"
            >
              <feather-icon
                size="16"
                icon="PlusCircleIcon"
                class="mr-50"
                @click="row.toggleDetails"
              />
            </b-button>
          </template>
        </template>

        <!--Show Details Data-->
        <template #row-details="row">
          <b-card>
            <template
              v-for="x in conf.tableData().tableHeader"
            >
              <b-row
                v-if="x.showInDetails"
                :key="`sss${x.key}`"
                class="mb-1"
              >
                <b-col
                  class="text-right"
                  cols="3"
                >
                  {{ humanise(x.key) }}
                </b-col>
                <b-col>
                  <template v-if="x.type === 'monetary'">
                    <vue-numeric
                      :key="x.id"
                      read-only
                      :precision="2"
                      :currency="myCurrency"
                      separator=","
                      :value="row.item[x.key]"
                    />
                  </template>
                  <template v-if="x.type === 'string'">
                    {{ humanise(row.item[x.key]) }}
                  </template>
                  <template v-if="x.type === 'bankname'">
                    {{ humanise(row.item[x.key]) }}
                  </template>
                  <template v-if="x.type === 'date'">
                    {{ transformDate(row.item[x.key], false, 'FF' ) }}
                  </template>
                  <template v-if="x.type === 'flag'">
                    {{ displaySystemConstantsLabel(x.key, row.item[x.key]) }}
                  </template>
                  <template v-if="x.type === 'number'">
                    {{ row.item[x.key] }}
                  </template>
                  <template v-if="x.type === 'decimal'">
                    {{ row.item[x.key] }}
                  </template>
                  <template v-if="x.type === undefined">
                    {{ row.item[x.key] }}
                  </template>
                  <template v-if="x.type === 'code'">
                    <code style="overflow-wrap: anywhere;">
                      {{ row.item[x.key] }}
                    </code>
                  </template>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </template>

        <template #row-details="row">
          <b-card>
            <b-table-simple
              hover
              small
              responsive
            >
              <b-thead>
                <b-tr>
                  <b-th>filename</b-th>
                  <b-th>file type</b-th>
                  <b-th class="text-right">
                    action
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="row.item.files.length > 0">
                  <template v-for="item in row.item.files">
                    <b-tr :key="item.id">
                      <b-td>{{ item.filename }}</b-td>
                      <b-td>{{ item.file_type }}</b-td>
                      <b-td class="text-right">
                        <b-button
                          size="sm"
                          @click="download(item.filename)"
                        >
                          <i class="fa fa-sync" /> Download
                        </b-button>
                      </b-td>
                    </b-tr>
                  </template>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td colspan="3">
                      <div class="text-center p-2">
                        No files attached
                      </div>
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>

            <b-row>
              <b-col
                sm="2"
                class="text-sm-right"
              >
                <b>Created at:</b>
              </b-col>
              <b-col>{{ transformDate(row.item.created_at, false, 'FF' ) }}</b-col>
            </b-row>
          </b-card>
        </template>

        <!--REUSABLE SYSTEM WIDE-->
        <template
          #cell(record_status)="
            row"
        >
          <span :class="[{'text-success': row.item.record_status === 1}, {'text-warning': row.item.record_status === 2}]">
            {{ humanise(displaySystemConstantsLabel('record_status', row.item.record_status)) }}</span>
        </template>

        <template #cell(invoice_status)="row">
          <b-badge
            size="sm"
            :class="[{'badge-success': row.item.invoice_status_id === 2},{'badge-danger': row.item.invoice_status_id === 3}, {'badge-warning': row.item.invoice_status_id === 1}]"
          >
            {{ row.item.invoice_status }}
          </b-badge>
        </template>

        <template #cell(status)="row">
          <b-badge
            size="sm"
            :class="[{'badge-success': row.item.status === 4},{'badge-danger': row.item.status === 5},{'badge-warning': row.item.status === 2}, {'badge-primary': row.item.status === 1 || row.item.status === 3}]"
          >
            {{ humanise(transposeStatus(row.item.status) ) }}
          </b-badge>
        </template>

        <!--COLLECTION URL-->
        <template #cell(url)="row">
          <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">{{ collectionBaseUrl }}</pre>
          <b-link
            v-if="row.item.collection_method === 1"
            target="_blank"
            :href="`${collectionBaseUrl}${row.item.uuid}`"
          ><feather-icon
            icon="ExternalLinkIcon"
          /></b-link>
          <b-link
            v-if="row.item.collection_method === 2"
            target="_blank"
            :href="`${collectionBaseUrl}${row.item.uuid}`"
          ><feather-icon
            icon="ExternalLinkIcon"
          /></b-link>
        </template>

        <!--BILL PAYMENT URL-->
        <template #cell(invoiceUrl)="row">
          <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">{{ invoiceBaseUrl }}</pre>
          <b-link
            target="_blank"
            :href="`${invoiceBaseUrl}${row.item.invoice_no}`"
          ><feather-icon
            icon="ExternalLinkIcon"
          /></b-link>
        </template>

        <!--BILL PAYMENT URL-->
        <template #cell(auditUrl)="row">
          <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">{{ row.item.url }}</pre>
          <code>
            {{ row.item.url }}
          </code>
        </template>

      </b-table>

      <LeanpayDatatablePaginationOnboarding
        :grid-settings="GridSettings"
        :selected-per-page="selectedPerPage"
        :current-page="currentPage"
        :search-field="searchField"
        :is-busy="isBusy"
        :table-settings="tableSettings"
        @paginationClicked="pagination($event)"
      />

      <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">{{ tableSettings }}</pre>

    </b-card>

    <b-modal
      id="userOnboardingDetailModal"
      size="xl"
      title="User Onboarding Detail"
      header-bg-variant="primary"
      ok-only
    >
      <template #modal-header>
        <div class="d-flex justify-content-between">
          <h4 class="mb-0 text-white py-1">
            User Onboarding Status
          </h4>
        </div>
      </template>
      <onboard-detail-view :selected-user-data="userOnboardingDetail" />
    </b-modal>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import humaniseMixin from '@/common/humanise.mixin'
import LeanpayDatatablePaginationOnboarding from '@/leanpay-components/LeanpayDatatablePaginationOnboarding.vue'
import OnboardDetailView from '@/views/administration/onboarding/onboardDetailView.vue'

export default {
  components: { OnboardDetailView, LeanpayDatatablePaginationOnboarding, flatPickr },
  mixins: [humaniseMixin],
  props: {
    tabObject: {
      required: false,
      type: Array,
      default() {
        return []
      },
    },
    endpointMode: {
      required: false,
      type: String,
      default: 'fastapi',
    },
    storeModule: {
      required: true,
      type: String,
    },
    storeDataEndpoint: {
      required: true,
      type: String,
    },
    loadSystemConstant: {
      type: Boolean,
      default: false,
    },
    storeGetterEndpoint: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    queryParam: {
      required: false,
      type: String,
    },
    rawDataKey: {
      required: false,
      type: String,
      default: 'getRawData',
    },
    defaultSortField: {
      required: false,
      type: String,
      default: 'created_at',
    },
    defaultSearchField: {
      required: true,
      type: String,
    },
    rawData: {
      required: false,
      type: Object,
    },
    paginationStateKey: {
      required: false,
      type: String,
    },
    searchOptionsDropdown: {
      required: false,
      type: Array,
    },
    conf: {
      required: false,
      type: Object,
    },
    pageConf: {
      required: false,
      type: Object,
    },
    currentTab: {
      required: false,
      type: Number,
      default() {
        return this.tabObject[0].tabValue
      },
    },
    features: {
      required: false,
      type: Object,
      default() {
        return {
          datatableFeatures: {
            buttons: {
              createButton: false,
              refresh: false,
            },
            perPageDropdown: true,
            filter: true,
            pagination: true,
            search: false,
          },
        }
      },
    },
  },
  data() {
    return {
      rangeDate: null,
      cardTitle: 'title',
      dataGridEndpoint: [],
      module: 'onboarding',

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortDesc: true,

      record_status: 1, // active only
      searchField: '',
      sortBy: 'desc',
      sort: '',

      userOnboardingDetail: null,
    }
  },
  computed: {
    currentTabValue() {
      return this.currentTab
    },
    bankList() {
      const bankList = this.$store.getters['systemConstants/getSystemConstantsByKey']('banks')
      if (bankList) {
        return bankList
      }
      return []
    },
    processedDateRange() {
      let dateRange = this.rangeDate
      if (dateRange) {
        dateRange = dateRange.split(' to ')
        if (dateRange.length > 1) {
          return {
            startDate: dateRange[0],
            endDate: dateRange[1],
          }
        }
        return {
          startDate: dateRange[0],
          endDate: dateRange[0],
        }
      }
      return this.defaultDateRange
    },
    formSchema() {
      const list = this.$store.getters[`${this.storeModule}/${this.storeGetterEndpoint}`]
      // return Object.getOwnPropertyNames(list[0])
      const x = []
      for (const [key, value] of Object.entries(list[0])) {
        x.push({ name: key, type: typeof value })
      }
      return x
    },
    tableConfig() {
      if (this.conf) {
        return this.conf.tableData()
      }
      return {}
    },
    tableSettings() {
      const list = this.$store.getters[`${this.storeModule}/${this.rawDataKey}`](this.paginationStateKey)
      // const { list } = dummyRes.data
      const perPage = this.selectedPerPage
      let listLength
      if (list !== null) {
        listLength = this.GridSettings.recordsTotal
      } else {
        listLength = 100
      }
      return {
        rows: listLength,
        perPage,
        refresh: true,
        filter: false,
        pagination: true,
        search: true,
      }
    },
    TableHeader() {
      const x = this.tableConfig.tableHeader
      if (this.features.showDTDetails) {
        return x.filter(y => y.visible)
      }
      return x.filter(y => y.visible && y.key !== 'show_details')
    },
    TableActions() {
      const x = this.tableConfig.tableAction
      return x.filter(y => y.enable)
    },
    sortObj() {
      const obj = {
        parameter_name: this.sort,
        sort_type: this.sortDesc ? 'desc' : 'asc',
      }
      return obj
    },
    GridData() {
      const list = this.$store.getters[`${this.storeModule}/${this.storeGetterEndpoint}`]
      if (list !== null) {
        return list
      }
      return []
    },
    GridSettings() {
      const list = this.$store.getters[`${this.storeModule}/${this.rawDataKey}`](this.paginationStateKey)

      console.log('---->', list)
      if (list !== null) {
        return {
          draw: list.per_page,
          recordsTotal: list.total,
          next_page: list.next_page,
          recordsFiltered: list.record_filtered,
        }
      }
      return []
    },
  },
  async mounted() {
    this.isBusy = false

    this.searchField = this.defaultSearchField
    this.sort = this.defaultSortField

    if (this.loadSystemConstant) {
      await this.$store.dispatch('systemConstants/getSystemConstants')
    }

    this.currentTab = 2

    let payload = {
      form_status: this.currentTab,
      page: 1,
      per_page: this.tableSettings.perPage,
      start_date: this.processedDateRange.startDate,
      end_date: this.processedDateRange.endDate,
      search_column: 'created_at',
      search_key: '',
      sort_column: this.sort,
      sort_type: this.sortObj.sort_type,
    }

    if (this.queryParam && typeof this.queryParam === 'string') {
      const y = this.queryParam
      const obj = { [y]: this.toCamelCase(this.queryParam) }

      payload = { ...obj, ...payload }
    }

    await this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload)
  },
  methods: {

    clearDateSearch() {
      this.rangeDate = null
      this.searchTerm = ''
      this.initDataGrid(this.currentTab)
    },
    transposeStatus(id) {
      const formStatuses = this.$store.getters['systemConstants/getOnboardSystemConstantsByKey']('formStatus')

      if (typeof formStatuses === 'object' && formStatuses !== null) {
        return Object.keys(formStatuses).find(key => formStatuses[key] === id)
      }
      return typeof formStatuses
    },
    approveRow(formId, userType) {
      console.log('DT hardcoded userType ', userType)
      const payload = {
        // mastermerchant : 3
        // whitelabel : 4
        user_type: 3,
        form_id: formId,
      }
      console.log(payload)

      const swalSettings = {
        ...this.swalDefault,
        title: 'Confirm Approve?',
        confirmButtonText: 'Confirm',
        customClass: {
          container: 'confirmation-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }

      this.$swal(swalSettings).then(result => {
        console.log(result)
        if (result.isConfirmed) {
          // this.$store.dispatch(`${this.module}/getPortalOnboardingList`, payload)
          this.$store.dispatch(`${this.module}/approvePortalOnboardingUser`, payload).then(() => {
            this.initDataGrid(this.currentTab)
          })
        }
      })
    },
    rejectRow(userId, formId) {
      const payload = {
        form_id: formId,
      }

      const swalSettings = {
        ...this.swalDefault,
        title: 'Confirm Reject?',
        confirmButtonText: 'Confirm',
        customClass: {
          // container: 'confirmation-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-danger mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }

      this.$swal(swalSettings).then(result => {
        console.log(result)
        if (result.isConfirmed) {
          // this.$store.dispatch(`${this.module}/getPortalOnboardingList`, payload)
          this.$store.dispatch(`${this.module}/rejectPortalOnboardingUser`, payload).then(() => {
            this.initDataGrid(this.currentTab)
          })
        }
      })
    },
    download(itemId) {
      // downloadOnboardingFile
      console.log('download', itemId)
      this.$store.dispatch(`${this.storeModule}/downloadOnboardingFile`, {
        file_name: itemId,
      })
    },
    getTableKey(x) {
      return `cell(${x.key})`
    },
    getShowTableKey(x) {
      return `cell(${x.key})`
    },
    displaySystemConstantsLabel(key, id = null) {
      const recordStatusList = this.$store.getters['systemConstants/getSystemConstantsByKey'](key)
      if (Array.isArray(recordStatusList) && recordStatusList.length && id !== null) {
        const x = recordStatusList.filter(el => el.value === parseInt(id, 10))
        // return x
        return this.humanise(
          x[0].name,
        )
      }
      return 'NA'
    },
    editRow(itemId) {
      console.log('edit', itemId)
      this.$router.push({
        name: 'administration-onboarding-detail-edit',
        params: { id: itemId, mode: 'edit' },
      })
    },
    deleteRow(itemId) {
      console.log('delete', itemId)
    },
    openModalRow(itemRowData, itemId) {
      console.log('itemRowData', itemRowData)
      console.log('openModal', itemId)

      this.userOnboardingDetail = itemRowData
      this.showDetailModal('userOnboardingDetailModal')
    },
    viewRow(itemRowData, itemId) {
      console.log('view', itemId)

      this.$store.dispatch('onboarding/updateSelectedPortalOnboardingUserDetail', itemRowData).then(() => {
        this.$router.push({
          name: 'administration-onboarding-detail-view',
          params: { id: itemId, mode: 'view' },
        })
      })
    },
    showDetailModal(modalId) {
      this.$bvModal.show(modalId)
    },
    create() {
      this.$router.push({
        name: 'merchant-api-new',
      })
    },
    pagination(e) {
      // const payload = {
      //   start_date: this.processedDateRange.startDate,
      //   end_date: this.processedDateRange.endDate,
      //   limit: this.tableSettings.perPage,
      //   skip: (e - 1) * this.tableSettings.perPage,
      //   record_status: 1,
      //   search: {
      //     search_enable: false,
      //     search_key: this.searchTerm,
      //     search_column: this.searchField,
      //     search_replace_word_enable: true,
      //     search_word_replace: ' ',
      //     search_word_replace_to: '_',
      //   },
      //   sort: this.sortObj,
      // }
      const payload = {
        form_status: this.currentTab,
        page: e,
        per_page: this.tableSettings.perPage,
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        search_column: '',
        search_key: '',
        sort_column: this.sort,
        sort_type: this.sortObj.sort_type,
      }
      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.isBusy = false
        this.currentPage = e
      })
    },
    sortingChanged(ctx) {
      this.sortDesc = !this.sortDesc
      this.sortBy = ctx.sortBy
      const payload = {
        form_status: this.currentTab,
        page: 1,
        per_page: this.tableSettings.perPage,
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        search_column: '',
        search_key: '',
        sort_column: this.sort,
        sort_type: this.sortObj.sort_type,
      }
      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    searchGrid() {
      const payload = {
        form_status: this.currentTab,
        page: 1,
        per_page: this.tableSettings.perPage,
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        search_column: this.searchField,
        search_key: this.searchTerm,
        sort_column: this.sort,
        sort_type: this.sortObj.sort_type,
      }
      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    setTabActive(tabValue) {
      this.tabObject.forEach(el => el.active = false)
      const theObjectIndex = this.tabObject.findIndex(el => el.tabValue === tabValue)
      this.tabObject[theObjectIndex].active = true

      const theObjectActjveIndex = this.tabObject.filter(el => el.active)
      this.currentTab = theObjectActjveIndex[0].tabValue
    },
    initDataGrid(tabValue) {
      this.setTabActive(tabValue)
      let payload
      payload = {
        form_status: tabValue,
        page: 1,
        per_page: this.tableSettings.perPage,
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        search_column: '',
        search_key: '',
        sort_column: this.sort,
        sort_type: this.sortObj.sort_type,
      }

      if (this.queryParam && typeof this.queryParam === 'string') {
        const y = this.queryParam
        const obj = { [y]: this.toCamelCase(this.queryParam) }

        payload = { ...obj, ...payload }
      }

      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload)
    },
  },
}
</script>

<style>
.show-details.col-width { width: 20px; padding: 0}
</style>
